<div class="container">
  <!-- Outer Row -->
  <div class="row justify-content-center">
    <div class="col-xl-10 col-lg-12 col-md-9">
      <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
          <!-- Nested Row within Card Body -->
          <div class="row">
            <div class="col-lg-12">
              <div class="p-5">
                <div class="text-center">
                  <img height="100" width="300" src="https://www.cagri.com/Uploads/EditorUploads/logo.png">
                  <br>
                  <h1 class="h4 text-gray-900 mb-4">Mağaza Destek Paneli</h1>
                </div>
                
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <div class="form-group">
                    <label for="username">Kullanıcı Adı</label>
                    <input
                      type="text"
                      formControlName="Username"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.Username.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.Username.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.Username.errors.required">
                        Zorunlu **
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="password">Şifre</label>
                    <input
                      type="password"
                      formControlName="Password"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.Password.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.Password.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.Password.errors.required">
                        Zorunlu **
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <button [disabled]="loading" class="btn btn-primary">
                      <span
                        *ngIf="loading"
                        class="spinner-border spinner-border-sm mr-1"
                      ></span>
                      Giriş Yap
                    </button>
                  </div>
                </form>
                <hr />
                <div class="text-center" hidden>
                  <a class="small">Şifremi Unuttum</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
