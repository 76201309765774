import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './interceptiors/auth.interceptor';
import { LoginComponent } from './components/auth/login/login/login.component';
import { ProfileComponent } from './components/auth/profile/profile/profile.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { FooterComponent } from './components/footer/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar/sidebar.component';
import { TalepComponent } from './components/talep/talep/talep.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AlertComponent } from './components/alert/alert/alert.component';
import { TalepaddComponent } from './components/talepadd/talepadd/talepadd.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { TalepdetayComponent } from './components/talepdetay/talepdetay/talepdetay.component';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { Talepadd2Component } from './components/talepadd2/talepadd2.component';
const accountModule = () => import('./components/auth/auth.module').then(x => x.AccountModule);



@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    TalepComponent,
    AlertComponent,
    TalepaddComponent,
    TalepdetayComponent,
    DashboardComponent,
    Talepadd2Component,
  ],
  imports: [ 
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatToolbarModule,
    MatButtonModule,
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatIconModule,
    MatCardModule,
    ReactiveFormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
