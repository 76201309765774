<div *ngIf="dataLoaded == false" class="d-flex justify-content-center">
  <div class="spinner-border text-success" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<div class="container">
<div class="container-fluid" *ngIf="dataLoaded">
  <div class="card">
    <h4 class="card-header">Yeni Talep Oluştur</h4>
    <div class="card-body">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="status">Öncelik Seviyesi (Zorunlu)</label>
          <select class="form-control" formControlName="oncelik">
            <option value="">Öncelik Seviyesi Seçiniz</option>
            <option value="1">Çok Acil</option>
            <option value="2">Acil</option>
            <option value="3">Normal</option>
            <option value="4">Düşük</option>
            <option value="5">Çok Düşük</option>
          </select>
          <div id="error" class="invalid-feedback">Please make a selection.</div>

        </div>
        

        <div class="form-group">
          <label for="status">Kayıt Tipileri (Zorunlu)</label>
          
          <select class="form-control" formControlName="cihazturid" > 
            <option value="">Kayıt Tipi Seçiniz</option>
            <option *ngFor="let suit of kayitTurModels" value="{{ suit.Id }}"  >{{suit.Name}}</option>
          </select> 
         

        </div>

        <div class="form-group">
          <label for="text">Konu (Zorunlu)</label>
          <input
            type="text"
            formControlName="konu"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.konu.errors }"
          />
          <div *ngIf="submitted && f.konu.errors" class="invalid-feedback">
            <div *ngIf="f.konu.errors.required">Konu Boş olamaz</div>
          </div>
        </div>
        <div class="form-group">
          <label for="text">Açıklama (Zorunlu)</label>

          <textarea
            formControlName="sikayet"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.sikayet.errors }"
            rows="3"
          ></textarea>
          <div *ngIf="submitted && f.sikayet.errors" class="invalid-feedback">
            <div *ngIf="f.sikayet.errors.required">Şikayet Boş Olamaz</div>
          </div>
        </div>

        <div class="form-group">
          <label for="file">File</label>
          <input
              formControlName="file"
              id="file"
              type="file"
              multiple
              class="form-control"
              (change)="onFileChange($event)">
        
      </div>

        <div class="form-group">
          <button [disabled]="loading" class="btn btn-primary">
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Oluştur
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>