import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let user = this.localStorageService.getUser();

    if (!user) {
      return next.handle(request);
    }

    let newrequest: HttpRequest<any>;
    newrequest = request.clone({
      //headers: request.headers.set('Authorization', 'Bearer ' + user.Token),
      headers: request.headers.set('Token', user.Token),
    });

    return next.handle(newrequest);
  }
}
